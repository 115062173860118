import React, { useEffect } from "react"
import { PageLayoutProps } from "../types/pages"
import { seoUtils } from "../utils/containers"
import Seo from "../components/partials/seo"
import AOS from "aos"

const PageLayout: React.FC<PageLayoutProps> = ({ children, seo }) => {
  useEffect(() => {
    AOS.init({
      offset: 30,
      disable: "tablet",
      once: true,
    })
  }, [])

  return (
    <div style={{ overflow: "hidden" }}>
      <Seo utils={seoUtils(seo)} />
      {children}
    </div>
  )
}

export default PageLayout
