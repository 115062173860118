import React from "react"
import { Helmet } from "react-helmet"
import { SeoProps } from "../../types/components"

const Seo: React.FC<SeoProps> = ({ utils }) => {
  let { lang } = utils
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={utils?.title}
      titleTemplate={utils.title ? utils?.title : "Como Consult"}
      meta={[...utils?.meta].concat(utils?.meta)}
    />
  )
}

export default Seo
